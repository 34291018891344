import { ChangeEvent, useMemo, useState } from "react";
import { PortConfigResponse, PortConfigUpdateRequest } from "data/schemas/portConfig";

type Props = {
    portList: PortConfigResponse[];
    handleChangeStatusPort: (body: PortConfigUpdateRequest) => void;
}

function PortConfigTable(props: Props) {
    const { portList, handleChangeStatusPort } = props;
    const [portListConfig, setPortListConfig] = useState<PortConfigResponse[]>();

    // useMemo(() => {
    //     if (portList) {
    //         setPortListConfig(portList);
    //     }
    // }, [portList])

    const handleChangeStatus = (event: ChangeEvent<HTMLInputElement>, port: PortConfigResponse) => {
        // setPortListConfig(prevPortListConfig =>
        //     prevPortListConfig?.map(value => {
        //         return value.portList === port.portList
        //             ? { ...value, portStatus: !event.target.checked ? 'inactive' : 'active' }
        //             : value
        //     })
        // );

        handleChangeStatusPort({
            group: port.group,
            id: port.id,
            portList: port.portList,
            portStatus: !event.target.checked ? 'inactive' : 'active'
        });
    };

    return (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <th scope="col" className="px-6 py-3">ID</th>
                    <th scope="col" className="px-6 py-3">Group</th>
                    <th scope="col" className="px-6 py-3">Domain</th>
                    <th scope="col" className="px-6 py-3">Name</th>
                    <th scope="col" className="px-6 py-3">Port List</th>
                    <th scope="col" className="px-6 py-3">Port Stauts</th>
                </thead>
                <tbody>
                    {portList && portList.map((value, index) => (
                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{value.id}</td>
                            <td className="px-6 py-4">{value.group}</td>
                            <td className="px-6 py-4">{value.domain}</td>
                            <td className="px-6 py-4">{value.name}</td>
                            <td className="px-6 py-4">{value.portList}</td>
                            <td className="px-6 py-4">
                                <label className="inline-flex items-center cursor-pointer">
                                    <input type="checkbox" className="sr-only peer"
                                        checked={value.portStatus === 'active' ? true : false}
                                        onChange={(e) => {
                                            handleChangeStatus(e, value);
                                        }} />
                                    <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                </label></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PortConfigTable;