import { Button, FormControl, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Textarea } from "@chakra-ui/react";
import { TransportRequestDetail, TransportRequestTag } from "data/TransportRequest";
import { useCallback, useState } from "react";
import CardTransportRequestPRD from "./CardTransportRequestPRD";
import { Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { checkDllVersion, CheckDllVersionParams } from "data/ListDll";
import Swal from "sweetalert2";
import useLoader from "hooks/useLoader";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    selectedTagForPRD: TransportRequestTag;
    handleConfirmCreateTR: (tag: TransportRequestTag) => void
}

function ModalReadyForPRD(props: Props) {
    const loading = useLoader();
    const { isOpen, onClose, selectedTagForPRD, handleConfirmCreateTR } = props

    const handleCloseModal = useCallback(() => {
        setCheckedState({});
        setTransportRequestStandBy([]);
        onClose();
    }, [onClose]);

    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date);
    const [transportRequestStandBy, setTransportRequestStandBy] = useState<TransportRequestDetail[]>()
    const [checkedState, setCheckedState] = useState<{ [key: string]: boolean }>({});

    const isAllowedDay = (date: Date) => {
        const day = date.getDay();
        return day === 1 || day === 3 || day === 5;
    };

    const handleSelectFileStandByPRD = async (details: TransportRequestDetail, checked: boolean) => {
        const isNotScriptDb = details.module.toLowerCase() !== "scriptdb";

        if (checked && isNotScriptDb) {
            const response = await checkVersionDll({
                fileName: details.deploymentObject,
                module: details.module,
                version: details.version,
                currentTag: selectedTagForPRD.uatTag
            });

            // const { buildDiff, majorDiff, minorDiff, reversionDiff } = response.diffVersion;

            // const isVersionDifferenceTooHigh =
            //     (buildDiff + majorDiff + minorDiff + reversionDiff) > 1;

            if (response && response.transportRequest.length > 0) {
                setCheckedState(prevState => ({
                    ...prevState,
                    [details.deploymentObject]: false,
                }));

                const transportRequestList = response.transportRequest
                    .map(value => `
                        <li>
                            <strong>${value.uatTag}</strong> version : <strong>${value.version}</strong>
                        </li>
                        Dev: ${value.createdBy}`)
                    .join('');

                Swal.fire({
                    icon: "warning",
                    title: "ไม่สามารถนำไฟล์นี้เตรียมขึ้น PRD ได้",
                    html: `
                            <p>เนื่องจากความห่างของ Version</p>
                            <p>กรุณาติดต่อผู้พัฒนา:</p>
                            <ul>
                                ${transportRequestList}
                            </ul>
                        `,
                });

                return;
            }
        }

        setCheckedState(prevState => ({
            ...prevState,
            [details.deploymentObject]: checked
        }));

        setTransportRequestStandBy(prev => {
            const previousArray = prev ?? [];

            if (checked) {
                const alreadyExists = previousArray.some(item =>
                    item.deploymentObject === details.deploymentObject &&
                    item.module === details.module
                );
                return alreadyExists ? prev : [...previousArray, details];
            } else {
                return previousArray.filter(item =>
                    item.deploymentObject !== details.deploymentObject ||
                    item.module !== details.module
                );
            }
        });
    };

    const checkVersionDll = async (params: CheckDllVersionParams) => {
        try {
            loading.show();
            const response = await checkDllVersion(params)

            return response
        } catch {
            Swal.fire({
                icon: 'error',
                title: 'ไม่สามารถตรวจสอบ Version Dll ได้'
            })
        } finally {
            loading.hide();
        }
    }

    const handleConfirmCreateTRPRD = () => {
        const prdTag = `PRD${selectedDate?.getFullYear()}-${String((selectedDate?.getMonth() ?? 0 + 1) + 1).padStart(2, '0')}-${String(selectedDate?.getDate()).padStart(2, '0')}-001`;

        const transportRequestGruop: TransportRequestTag = {
            prdTag,
            developer: selectedTagForPRD.developer,
            remark: selectedTagForPRD.remark,
            uatTag: selectedTagForPRD.uatTag,
            details: selectedTagForPRD.details,
            transportRequestDetail: transportRequestStandBy!
        }

        handleConfirmCreateTR(transportRequestGruop);

        setTransportRequestStandBy([]);
        setSelectedDate(new Date);
    }

    return (
        <Modal
            //isCentered
            size="6xl"
            isOpen={isOpen}
            onClose={handleCloseModal}
            closeOnOverlayClick={false}
            scrollBehavior="inside"
        >
            <ModalOverlay />
            <ModalContent className="h-screen">
                <ModalHeader className="border-b-2">Ready For PRD</ModalHeader>
                <ModalCloseButton className="border hover:bg-red-300" />
                <ModalBody>
                    <div className="flex h-full flex-row">
                        <div className="flex h-full w-2/4 flex-1 flex-col gap-y-4 pr-2">
                            <div className="flex flex-col gap-y-2 rounded-md border border-neutral-400 p-2">
                                <div
                                    id="row-1"
                                    className="grid grid-cols-2 gap-x-2"
                                >
                                    <div className="flex flex-col gap-y-2 rounded-md border border-neutral-400 p-2">
                                        <div
                                            id="row-1"
                                            className="grid grid-cols-2 gap-x-2"
                                        >
                                            <div className="flex flex-col gap-2">
                                                <div className="flex flex-col">
                                                    <span className="text-sm font-medium">
                                                        Ticket
                                                        <span className="relative bottom-1 font-bold text-red-600">
                                                            *
                                                        </span>
                                                    </span>
                                                    <Input
                                                        name="ticket_no"
                                                        className="rounded-sm border-neutral-400 text-black"
                                                        placeholder="Enter The Ticket Number"
                                                        value={selectedTagForPRD.transportRequestDetail[0]?.ticket}
                                                        errorBorderColor="crimson"
                                                        disabled
                                                    />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="text-sm font-medium">
                                                        Impact
                                                        <span className="relative bottom-1 font-bold text-red-600">
                                                            *
                                                        </span>
                                                    </span>
                                                    <Input
                                                        name="impact"
                                                        placeholder="กรุณาระบุผลกระทบ"
                                                        className="rounded-sm border-neutral-400 text-black"
                                                        value={selectedTagForPRD.transportRequestDetail[0]?.impact}
                                                        errorBorderColor="crimson"
                                                        disabled
                                                    />
                                                </div>
                                                <div className="flex flex-col">
                                                    <span className="text-sm font-medium">
                                                        Risk Level
                                                        <span className="relative bottom-1 font-bold text-red-600">
                                                            *
                                                        </span>
                                                    </span>
                                                    <FormControl>
                                                        <Select
                                                            name="risk_level"
                                                            className="rounded-sm border-neutral-400 text-black"
                                                            size="sm"
                                                            placeholder="Please choose one"
                                                            value={selectedTagForPRD.transportRequestDetail[0]?.riskLevel}
                                                            errorBorderColor="crimson"
                                                            disabled
                                                        >
                                                            <option value="LOW">Low</option>
                                                            <option value="MEDIUM">Medium</option>
                                                            <option value="HIGH">High</option>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="text-sm font-medium">
                                                    Detail
                                                    <span className="relative bottom-1 font-bold text-red-600">
                                                        *
                                                    </span>
                                                </span>
                                                <Textarea
                                                    name="detail"
                                                    size="sm"
                                                    className="flex-1 rounded-sm border-neutral-400 text-black"
                                                    placeholder="กรุณาระบุรายละเอียดงาน"
                                                    value={selectedTagForPRD.details}
                                                    errorBorderColor="crimson"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div
                                            id="row-2"
                                            className="grid grid-cols-2 gap-x-2"
                                        >
                                            <div className="flex flex-col justify-center gap-2">
                                                <div className="flex flex-col">
                                                    <span className="text-sm font-medium">Feature</span>
                                                    <Textarea
                                                        name="feature"
                                                        size="sm"
                                                        className="flex-1 rounded-sm border-neutral-400 text-black"
                                                        placeholder="กรุณาระบุรายละของ Feature"
                                                        value={selectedTagForPRD.transportRequestDetail[0]?.feature}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-col justify-center gap-2">
                                                <div className="flex flex-col">
                                                    <span className="text-sm font-medium">Remark</span>
                                                    <Textarea
                                                        name="remark"
                                                        size="sm"
                                                        className="flex-1 rounded-sm border-neutral-400 text-black"
                                                        placeholder="หมายเหตุเพิ่มเติม"
                                                        value={selectedTagForPRD.remark}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <DatePicker
                                                selected={selectedDate}
                                                onChange={(date) => setSelectedDate(date)}
                                                filterDate={isAllowedDay}
                                                inline
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {selectedTagForPRD.transportRequestDetail.map((value, index) => (
                                            <CardTransportRequestPRD
                                                key={index}
                                                transportRequestDetail={value}
                                                checkedState={checkedState}
                                                handleSelectFileStandbyPRD={handleSelectFileStandByPRD}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        colorScheme="green"
                        onClick={handleConfirmCreateTRPRD}
                    >
                        Confirm
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}

export default ModalReadyForPRD;