import * as React from 'react';
import { Route, useHistory, useLocation } from 'react-router';

import NavMenu from '../components/NavMenu';
// import AdminDashboard from '../components/AdminDashboard';
import AdminDashboard from '../presentations/view/AdminDashboard/AdminDashboard.view';
import Home from '../components/Home';
import Counter from '../components/Counter';
import AdminDashboardWeb from '../components/AdminDashboardWeb';
import AdminDashboardOverView from '../components/AdminDashboardOverView';
import ViewDashboard from '../components/ViewDashboard/ViewDashboard';
import ViewDashboardServer from '../components/ViewDashboard/ViewDashboardServer';
import DashboardServerDetail from '../presentations/view/DashboardServerDetail/DashboardServerDetail.view';
import ManageAdminDashboard from '../components/ManageAdminDashboard';
import AdminDashboardApplicationServer from '../components/AdminDashboardApplicationServer';
// import AdminDashboardControl from '../components/AdminDashboard/AdminDashboardControl';
import AdminDashboardControl from '../presentations/view/AdminDashboardControl/AdminDashboardControl.view';
import UpdateLog from '../components/UpdateLog/UpdateLog';
import Ingress from '../components/Ingress/Ingress';
import TopNavigation from 'components/TopNavigation/TopNavigation';
import MonitoringSummaryPDF from 'presentations/view/MonitoringSummaryPDF/MonitoringSummaryPDF.view';
import F1ApiLogView from 'presentations/view/F1ApiLog/F1ApiLog.view';
import ServerMonitorView from '../presentations/view/ServerMonitor/ServerMonitor.view';
import PortConfigView from 'presentations/view/PortConfig/PortConfig.view';

export default (props: { children?: React.ReactNode }) => {
    const location = useLocation();
    const history = useHistory();

    React.useEffect(() => {
        const groupSession = sessionStorage.getItem('GROUP');
        const tokenSession = sessionStorage.getItem('MM_APPLICATION');

        const hasSessionValues = groupSession && tokenSession;

        if (hasSessionValues && !location.pathname.includes('server-monitor')) {
            history.replace({
                pathname: '/server-monitor',
                search: new URLSearchParams({
                    group: groupSession,
                    token: tokenSession
                }).toString()
            });
        }
    }, [location, history]);

    return (
        <React.Fragment>
            {/* <NavMenu /> */}
            <TopNavigation />
            <div className="container-fluid flex flex-1 flex-col">
                <Route
                    exact
                    path="/"
                    component={AdminDashboard}
                />
                <Route
                    path="/summary"
                    component={MonitoringSummaryPDF}
                />
                <Route
                    path="/home"
                    component={Home}
                />
                <Route
                    path="/counter"
                    component={Counter}
                />
                <Route
                    path="/web"
                    component={AdminDashboardWeb}
                />
                <Route
                    path="/sever"
                    component={AdminDashboard}
                />
                <Route
                    path="/admindashboard"
                    component={AdminDashboard}
                />
                <Route
                    path="/admindashboardoverview"
                    component={AdminDashboardOverView}
                />
                <Route
                    path="/viewdashboard/:page?/:id?"
                    component={ViewDashboard}
                />
                {/* <Route path="/ViewDashboardServer/:page?/:id?" component={ViewDashboardServer} /> */}
                <Route
                    path="/ViewDashboardServer/:page?/:id?"
                    component={DashboardServerDetail}
                />
                <Route
                    path="/manageadmindashboard/:page?/:id?"
                    component={ManageAdminDashboard}
                />
                <Route
                    path="/adminApplicationServer"
                    component={AdminDashboardApplicationServer}
                />
                <Route
                    path="/admindashboardcontrol/:id?"
                    component={AdminDashboardControl}
                />
                <Route
                    path="/updatelog/:id?"
                    component={UpdateLog}
                />
                <Route
                    path="/ingress"
                    component={Ingress}
                />
                <Route
                    path="/f1apilog"
                    component={F1ApiLogView}
                />
                <Route
                    path="/server-monitor"
                    component={ServerMonitorView}
                />
                <Route
                    path="/portconfig"
                    component={PortConfigView}
                />
            </div>
        </React.Fragment>
    );
};
