import { useStore } from "commons/hooks";
import { PortConfigResponse, PortConfigUpdateRequest } from "data/schemas/portConfig";
import useLoader from "hooks/useLoader";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

function PortConfigViewModel() {
    const portConfig = useStore().portConfig;
    const loading = useLoader();

    const [portListResponse, setPortListResponse] = useState<PortConfigResponse[] | null>();

    useEffect(() => {
        fetchPortListConfig()
    }, []);

    const fetchPortListConfig = async () => {
        try {
            loading.show();

            await portConfig.fetchListPortConfig();

            if (portConfig.portConfigResponse) {
                setPortListResponse(portConfig.portConfigResponse);
            }
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('Cannot get PortList', error.message, 'error');
            }
        } finally {
            loading.hide();
        }
    };

    const handleChangeStatusPort = async (body: PortConfigUpdateRequest) => {
        try {
            loading.show();

            await portConfig.updateStatusProt(body);

            if (portConfig.updateStatusResponse === 'success') {
                Swal.fire({
                    icon: 'success',
                    title: 'update status success',
                    text: `port ${body.portList} : ${body.portStatus} เสร็จสิ้น`
                });
            }
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('Cannot get PortList', error.message, 'error');
            }
        } finally {
            await fetchPortListConfig();
            loading.hide();
        }
    }

    return {
        portListResponse,

        handleChangeStatusPort
    }
}

export default PortConfigViewModel;