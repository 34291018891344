import React, { useRef, useState } from 'react';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { twMerge } from 'tailwind-merge';

interface UploadFileProps {
    id: string;
    label?: string;
    fileType: string;
    handleUploadFiles: (Files: File[], Id: string) => void;
    className?: string;
    fileUploadedLength: number;
}

function UploadFiles(props: UploadFileProps) {
    const { id, label, fileType, handleUploadFiles, className, fileUploadedLength } = props;
    const [dragging, setDragging] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (!files) {
            return;
        }

        handleUploadFiles(Array.from(files), event.target.id);
    };

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (event: React.DragEvent) => {
        event.preventDefault();
        setDragging(false);

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            const files = event.dataTransfer.files;

            if (!files) {
                return;
            }

            handleUploadFiles(Array.from(files), id);
            event.dataTransfer.clearData();
        }
    };

    //หาวิธีแก้อันนี้

    return (
        <React.Fragment>
            <div
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                className={twMerge(
                    'cursor-pointer rounded-md border border-neutral-400 p-8 hover:shadow-md hover:shadow-neutral-300',
                    className
                )}
                onClick={() => {
                    fileInputRef.current?.click();
                }}
            >
                <div className="flex items-center justify-center gap-2">
                    <AiOutlineCloudUpload
                        className="text-sky-500"
                        size={30}
                    />
                    <div className="flex flex-col">
                        <span className="text-sm text-neutral-500">
                            Drop or Browse{' '}
                            <span className="font-bold text-sky-500 underline">{label}</span>
                        </span>
                        {fileUploadedLength > 0 ? (
                            <span className="text-xs text-orange-500">
                                Uploaded {fileUploadedLength} file(s)
                            </span>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                    </div>
                </div>
            </div>

            <input
                id={id}
                ref={fileInputRef}
                type="file"
                multiple
                accept={fileType}
                onChange={event => {
                    handleChangeFile(event);
                    event.target.value = '';
                }}
                className="hidden"
            />
        </React.Fragment>
    );
}

export default UploadFiles;
