import axios from "axios";
import {
    TransportRequestTag,
    TransportRequestEntity,
    TransportRequestPrdParams
} from "./transportRequest.schema";

export async function callAddTransportRequestPRD(body: TransportRequestTag) {
    return axios.post<string>('v1/transport-request/prd/add', body);
}

export async function retrieveTransportRequestPrd(params: TransportRequestPrdParams) {
    return axios.get<TransportRequestEntity[]>('v1/transport-request/prd/get', {
        params
    });
}