import React from "react";
import TransportRequestPRDViewModel from "./TransportRequestPRD.viewmodel";
import TransportRequestTablePRD from "./components/TransportRequestTablePRD";
import ReactPaginate from "react-paginate";
import { Button, Input } from "@chakra-ui/react";

function TransportRequestPRD() {
    const {
        totalPages,
        transportRequestPrd,

        handlePageChange,
        handleSearchChange,
        fetchTransportRequest,
        handleFetchGetPathFileByPrdTag
    } = TransportRequestPRDViewModel();

    return (
        <div>
            <div className="flex flex-row items-end gap-4">
                <div>
                    <label htmlFor="tag">PRD Tag : </label>
                    <Input
                        onChange={e => {
                            handleSearchChange('prdTag', e.target.value);
                        }}
                    />
                </div>
                <Button
                    onClick={() => {
                        fetchTransportRequest();
                    }}
                >
                    Search
                </Button>
                <div className="gap-2">
                    <label htmlFor="item">Item : </label>
                    <select
                        onChange={e => {
                            handleSearchChange('pageSize', parseInt(e.target.value));
                        }}
                    >
                        <option>15</option>
                        <option>25</option>
                        <option>35</option>
                        <option>45</option>
                    </select>
                </div>
            </div>

            <TransportRequestTablePRD
                transportRequestPrd={transportRequestPrd!}
                onClickDowloadFile={handleFetchGetPathFileByPrdTag}
            />

            {/* <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                previousLabel="<"
                pageRangeDisplayed={5}
                pageCount={totalPages}
                onPageChange={handlePageChange}
                renderOnZeroPageCount={null}
                activeClassName="active"
                containerClassName="pagination flex justify-end mt-2"
                pageLinkClassName="pagination flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full mr-2 cursor-pointer"
                activeLinkClassName="bg-blue-500 text-white"
                previousClassName="pagination flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full mr-2 cursor-pointer"
                nextClassName="pagination flex justify-center items-center w-8 h-8 border border-gray-300 rounded-full cursor-pointer"
            /> */}
        </div>
    );
}

export default TransportRequestPRD;