import axios from 'axios';
import {
    CreateTransportRequest,
    TransportRequestHeader,
    TransportRequestItem
} from 'data/schemas/TransportRequest';

export async function callCreateTransportRequestUatV2(params: CreateTransportRequest) {
    const formData = new FormData();

    const { FappFiles, FguiFiles, ScriptDbFiles, TransportRequestHeader } = params;

    // Add tr info
    Object.entries(TransportRequestHeader).forEach(([headerKey, headerValue]) => {
        const headerKeyTyped = headerKey as keyof typeof TransportRequestHeader;

        if (headerKeyTyped === 'TransportRequestItems') {
            (headerValue as Array<TransportRequestItem>).forEach((item, index) => {
                Object.entries(item).forEach(([itemKey, itemValue]) => {
                    // Add tr items to form-data
                    formData.append(
                        `TransportRequestHeader.TransportRequestItems[${index}].${itemKey}`,
                        itemValue
                    );
                });
            });
        } else {
            // Add tr header to form-data
            formData.append(`TransportRequestHeader.${headerKey}`, headerValue);
        }
    });

    // Add Files to form-data
    for (const fappFile of FappFiles) {
        formData.append('FappFiles', fappFile);
    }
    for (const fguiFile of FguiFiles) {
        formData.append('FguiFiles', fguiFile);
    }
    for (const scriptdbFile of ScriptDbFiles) {
        formData.append('ScriptDbFiles', scriptdbFile);
    }

    return await axios.post('v2/transport-request/uat/create', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
