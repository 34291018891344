import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useStore } from '../../../commons/hooks';
import useLoader from 'hooks/useLoader';
import Swal from 'sweetalert2';

export type AdminDashboardOnClickType =
    | 'NAVIGATE_TO_SERVER_DASHBOARD_DETAIL'
    | 'NAVIGATE_TO_ADMIN_SERVER_CONTROL';

function AdminDashboardViewModel() {
    const history = useHistory();
    const screenLoader = useLoader();
    const { serverConfigStore, authentication } = useStore();

    const monitorServers = serverConfigStore.monitorServers;
    const authenticationUser = authentication.masterEmployeeResponse;

    const [activeTab, setActiveTab] = useState('9');
    const [autoRefresh, setAutoRefresh] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const toggleRefresh = () => setRefresh(!refresh);

    useEffect(() => {
        _fetchMonitorServers();
        _autoRefreshTime(false);
    }, [refresh]);

    const onSelectTab = (tabNumber: string) => {
        setActiveTab(tabNumber);
    };

    const onchangeSwitch = (checked: boolean) => {
        if (checked === true) {
            setAutoRefresh(true);
            _autoRefreshTime(true);
        } else {
            setAutoRefresh(false);
        }
    };

    const handleClick = (event: AdminDashboardOnClickType, value: string) => {
        switch (event) {
            case 'NAVIGATE_TO_ADMIN_SERVER_CONTROL': {
                _navigateToAdminServerControl(value);

                break;
            }
            case 'NAVIGATE_TO_SERVER_DASHBOARD_DETAIL': {
                _navigateToServerDashboardDetail(value);

                break;
            }
            default:
                break;
        }
    };

    const _fetchMonitorServers = async () => {
        try {
            screenLoader.show();

            await serverConfigStore.fetchMonitorServers();
        } catch (error) {
            console.log('error', error);

            if (error instanceof Error) {
                Swal.fire('เกิดข้อผิดพลาด!', error.message, 'error');
            }
        } finally {
            screenLoader.hide();
        }
    };

    const _autoRefreshTime = (isRefresh: boolean) => {
        if (autoRefresh || isRefresh) {
            setTimeout(() => {
                toggleRefresh();
            }, 60000);
        }
    };

    const _navigateToServerDashboardDetail = (id: string) => {
        try {
            // set selected server
            serverConfigStore.setSelectedServer(id);

            // redirect to server detail
            history.push(`/ViewDashboardServer/adminServer/${id}`);
        } catch (error) {
            if (error instanceof Error) {
                _handleErrorNavigation(error.message);
            }
        }
    };

    const _navigateToAdminServerControl = (id: string) => {
        try {
            // set selected server
            serverConfigStore.setSelectedServer(id);

            // redirect to server control
            history.push(`/admindashboardcontrol/${id}`);
        } catch (error) {
            if (error instanceof Error) {
                _handleErrorNavigation(error.message);
            }
        }
    };

    const _handleErrorNavigation = (message: string) => {
        Swal.fire('เกิดข้อผิดพลาด!', message, 'error').then(result => {
            if (result.isConfirmed) {
                history.push('/');
            }
        });
    };

    return {
        activeTab,
        onSelectTab,
        handleClick,
        monitorServers,
        onchangeSwitch,
        autoRefresh,
        toggleRefresh,
        authenticationUser
    };
}

export default AdminDashboardViewModel;
