import 'assets/style/style.css';

import React from 'react';
import { observer } from 'mobx-react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

// import AdminDashboardServer from 'components/AdminDashboardServer';
import AdminDashboardTableComponent from './components/AdminDashboardTableComponent';
import DBWorker from 'components/DBWorker';
import DBComparison from 'components/DBComparison/DBComparison';
import UpdateLog from 'components/UpdateLog/UpdateLog';

import AdminDashboardViewModel from './AdminDashboard.viewmodel';
import ClearTransaction from '../ClearTransaction/ClearTransaction.view';
import Migration from '../Migration/Migration.view';
import TransportRequestUATView from '../TransportRequestUpdate/TransportRequestUAT.view';
import MonitorPerformance from '../MonitorPerformance/MonitorPerformance.view';
import F1ApiLogView from '../F1ApiLog/F1ApiLog.view';
import { serverMonitorColumns } from './data/serverMonitorColumns';
import WebMonitorDashboard from './components/WebMonitorDashboard';
import FAPPMonitorDashboard from './components/FAPPMonitorDashboard';
import FTPMonitorDashboard from './components/FTPMonitorDashboard';
import PortConfigView from '../PortConfig/PortConfig.view';
import TransportRequestPRD from '../TransportRequestPRD/TransportRequestPRD.view';

const AdminDashboard = () => {
    const {
        activeTab,
        onSelectTab,
        handleClick,
        monitorServers,
        onchangeSwitch,
        autoRefresh,
        toggleRefresh,
        authenticationUser
    } = AdminDashboardViewModel();

    return (
        <React.Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '1' ? 'active' : ''}
                        onClick={() => onSelectTab('1')}
                    >
                        Server
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '13' ? 'active' : ''}
                        onClick={() => onSelectTab('13')}
                    >
                        Web
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '14' ? 'active' : ''}
                        onClick={() => onSelectTab('14')}
                    >
                        FAPP
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '15' ? 'active' : ''}
                        onClick={() => onSelectTab('15')}
                    >
                        sFTP
                    </NavLink>
                </NavItem>
                <NavItem hidden={authenticationUser?.type !== 'super_admin'}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '2' ? 'active' : ''}
                        onClick={() => onSelectTab('2')}
                    >
                        DB Worker
                    </NavLink>
                </NavItem>
                {/*<NavItem>*/}
                {/*    <NavLink style={{ cursor: 'pointer' }} className={activeTab == '3' ? 'active' : ''} onClick={() => setActiveTab('3')}>*/}
                {/*        Application Server*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}
                {/*<NavItem>*/}
                {/*    <NavLink style={{ cursor: 'pointer' }} className={activeTab == '4' ? 'active' : ''} onClick={() => setActiveTab('4')}>*/}
                {/*        Web*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}
                <NavItem hidden={authenticationUser?.type !== 'super_admin'}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '5' ? 'active' : ''}
                        onClick={() => onSelectTab('5')}
                    >
                        DB Comparison
                    </NavLink>
                </NavItem>
                <NavItem hidden={authenticationUser?.type !== 'super_admin'}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '6' ? 'active' : ''}
                        onClick={() => onSelectTab('6')}
                    >
                        UpDate Log
                    </NavLink>
                </NavItem>
                <NavItem hidden={authenticationUser?.type !== 'super_admin'}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '7' ? 'active' : ''}
                        onClick={() => onSelectTab('7')}
                    >
                        Clear Transaction
                    </NavLink>
                </NavItem>
                <NavItem hidden={authenticationUser?.type !== 'super_admin'}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '8' ? 'active' : ''}
                        onClick={() => onSelectTab('8')}
                    >
                        Migration
                    </NavLink>
                </NavItem>
                <NavItem hidden={authenticationUser?.type !== 'super_admin'}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '16' ? 'active' : ''}
                        onClick={() => onSelectTab('16')}
                    >
                        Config Port
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '9' ? 'active' : ''}
                        onClick={() => onSelectTab('9')}
                    >
                        TransportRequest UAT
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '10' ? 'active' : ''}
                        onClick={() => onSelectTab('10')}
                    >
                        TransportRequest PRD
                    </NavLink>
                </NavItem>
                <NavItem hidden={authenticationUser?.type !== 'super_admin'}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '11' ? 'active' : ''}
                        onClick={() => onSelectTab('11')}
                    >
                        MonitorPerformance
                    </NavLink>
                </NavItem>
                <NavItem hidden={authenticationUser?.type !== 'super_admin'}>
                    <NavLink
                        style={{ cursor: 'pointer' }}
                        className={activeTab == '12' ? 'active' : ''}
                        onClick={() => onSelectTab('12')}
                    >
                        F1 Api Log
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent
                activeTab={activeTab}
                className="flex flex-1 flex-col py-2"
            >
                <TabPane
                    tabId={activeTab}
                    className="flex-1"
                >
                    {activeTab === '1' && (
                        <AdminDashboardTableComponent
                            columns={serverMonitorColumns}
                            data={monitorServers.ResponseAdminServer}
                            onCheckOnlineStatus={record => record.status === 'true'}
                            handleClickEvent={handleClick}
                            onToggleSwitch={onchangeSwitch}
                            isAutoRefresh={autoRefresh}
                            toggleRefresh={toggleRefresh}
                        />
                    )}
                    {activeTab === '13' && <WebMonitorDashboard />}
                    {activeTab === '14' && <FAPPMonitorDashboard />}
                    {activeTab === '15' && <FTPMonitorDashboard />}
                    {activeTab === '2' && <DBWorker />}
                    {/*{(activeTab === '2' && <TabPane tabId="2" className="flex-1"><AdminDashboardOverView /></TabPane>)}*/}
                    {/*{(activeTab === '3' && <TabPane tabId="3" className="flex-1"><AdminDashboardApplicationServer /></TabPane>)}*/}
                    {/*{(activeTab === '4' && <TabPane tabId="4" className="flex-1"><AdminDashboardWeb /></TabPane>)}*/}
                    {activeTab === '5' && <DBComparison />}
                    {activeTab === '6' && <UpdateLog />}
                    {activeTab === '7' && <ClearTransaction />}
                    {activeTab === '8' && <Migration />}
                    {activeTab === '9' && <TransportRequestUATView />}
                    {activeTab === '10' && <TransportRequestPRD />}
                    {activeTab === '11' && <MonitorPerformance />}
                    {activeTab === '12' && <F1ApiLogView />}
                    {activeTab === '16' && <PortConfigView />}
                </TabPane>
            </TabContent>
        </React.Fragment>
    );
};

export default observer(AdminDashboard);
