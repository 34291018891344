import { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { useBoolean } from '@chakra-ui/react';
import Swal from 'sweetalert2';

import {
    CreateTransportRequest,
    TransportRequestEntity,
    TransportRequestInsert,
    TransportRequestSearch,
    TransportRequestTag
} from 'data/schemas/TransportRequest';
import { useStore } from 'commons/hooks';
import useLoader from 'hooks/useLoader';
import { getFappPorts } from '../../../data/repositories/monitor/monitorFapp.repository';
import { FappConfig } from '../../../models/FappMonitor';
import { callCreateTransportRequestUatV2 } from 'data/datasources/v2/transportRequest';
import { editOrUpdateTransportRequest, getTransportRequestUatByTag } from 'data/repositories/TransportRequest';
import JSZip from 'jszip';
import axios from 'axios';
import { addTransportRequestPRD } from 'data/TransportRequest';
import { updateDllVersion } from 'data/ListDll';


function TransportRequestUATViewModel() {
    const screenLoader = useLoader();
    const { transportRequest, authentication } = useStore();
    const { updateTransportRequestResult } = transportRequest;
    const [totalPages, setTotalPage] = useState(1);
    const [isOpenModalDeploy, setOpenModalDeploy] = useState(false);
    const [currentItems, setCurrentItems] = useState<TransportRequestTag[]>();
    const [selectedDeploy, setSelectedDeploy] = useState<TransportRequestTag>({
        uatTag: '',
        details: '',
        developer: '',
        remark: '',
        transportRequestDetail: []
    });
    const [showReadyForPRDModal, setShowReadyForPRDModal] = useBoolean();
    const [showAddTransportRequestModal, setShowAddTransportRequestModal] = useBoolean();
    const [showEditransportRequestModal, setShowEditTransportRequestModal] = useBoolean();
    const [searchHelp, setSearchHelp] = useState<TransportRequestSearch>({
        uatTag: '',
        developer: '',
        deploymentObject: '',
        deployStatus: '',
        page: '1',
        pageSize: '15'
    });
    const [fappPorts, setFappPorts] = useState<FappConfig[]>([]);

    const refTransportRequest = useRef<TransportRequestTag>();

    useEffect(() => {
        getFappPortOptions();
    }, []);

    useEffect(() => {
        fetchTransportRequest();
    }, [searchHelp.pageSize, searchHelp.page]);

    const toggleModalDeploy = () => setOpenModalDeploy(!isOpenModalDeploy);

    const handlePageChange = ({ selected }) => {
        handleSearchChange('page', selected + 1);
    };

    const handleDeployDll = (deployTag: TransportRequestTag) => {
        setSelectedDeploy(deployTag);
        toggleModalDeploy();
    };

    const fetchTransportRequest = async () => {
        try {
            screenLoader.show();

            await transportRequest.fetchTransportRequestUat(searchHelp);

            if (transportRequest.transportRequestResponse !== null) {
                const resultList = transportRequest.transportRequestResponse.resultList;
                const totalCount = transportRequest.transportRequestResponse.totalCount;
                //handle error slice when no data ja
                if (resultList && totalCount !== undefined) {
                    setCurrentItems(resultList);
                    setTotalPage(Math.ceil(totalCount / parseInt(searchHelp.pageSize)));
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('Cannot get transport request!', error.message, 'error');
            }
        } finally {
            screenLoader.hide();
        }
    };

    const getFappPortOptions = async () => {
        try {
            // maybe use store for fetch
            const fappPortResultList = await getFappPorts({ isPRD: false });

            const activeFappPorts = [...fappPortResultList].filter(
                port => port.portStatus.toLowerCase() === 'active'
            );

            setFappPorts(activeFappPorts);
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('Cannot get fapp ports!', error.message, 'error');
            }
        }
    };

    const handleConfirmCreateTR = async (req: CreateTransportRequest) => {
        try {
            screenLoader.show();

            if (
                req.FappFiles.length === 0 &&
                req.FguiFiles.length === 0 &&
                req.ScriptDbFiles.length === 0
            ) {
                const swalAction = await Swal.fire(
                    'Create TR',
                    'Attach files not found.',
                    'warning'
                );

                if (swalAction.isConfirmed) {
                    return;
                }
            }

            await callCreateTransportRequestUatV2(req);
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('Error', error.message, 'error');
            }
        } finally {
            screenLoader.hide();

            await fetchTransportRequest();
        }
    };

    const handleConfirmDeployTR = async (portList: string[], uatTag: string) => {
        try {
            screenLoader.show();

            updateTransportRequestResult.commandExecutedOutput = [];

            const body = {
                username: authentication.masterEmployeeResponse?.ctizenID ?? '',
                ports: portList.join('|'),
                uatTag
            };

            await transportRequest.updateTransportRequest(body);
        } catch (e) {
            if (e instanceof Error) {
                Swal.fire('Error', e.message, 'error');
            }
        } finally {
            screenLoader.hide();
            await fetchTransportRequest();
        }
    };

    const handleSearchChange = <K extends keyof TransportRequestSearch>(
        key: K,
        value: TransportRequestSearch[K]
    ) => {
        setSearchHelp(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleShowModalEdit = (transportRequest: TransportRequestTag) => {
        refTransportRequest.current = transportRequest
        setShowEditTransportRequestModal.on()
    }

    const handleConfirmEditOrUpdateTransportRequest = async (body: TransportRequestTag) => {
        try {
            const response = await editOrUpdateTransportRequest(body);

            if (response === 'success') {
                Swal.fire({
                    title: 'Update',
                    icon: 'success',
                    text: 'ทำการแก้ไขเสร็จสิ้น'
                });
            }
        } catch {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'ไม่สามรถแก้ไขรายการได้'
            });
        } finally {
            fetchTransportRequest();
            setShowEditTransportRequestModal.off();
        }
    }

    const handleFetchGetPathFileByUatTag = async (uatTag: string) => {
        try {
            screenLoader.show();
            const response = await getTransportRequestUatByTag(uatTag);

            if (!response || response.length === 0) {
                return;
            }

            const zipBlob = await createZipFromFiles(response);

            if (zipBlob.size === 0) {
                return;
            } else {
                downloadBlob(zipBlob, uatTag);
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: (error as any).message
            })
        } finally {
            screenLoader.hide();
        }
    }

    const createZipFromFiles = async (fileEntities: TransportRequestEntity[]): Promise<Blob> => {
        const zip = new JSZip();

        for (const entity of fileEntities) {
            if (!entity.s3FilePath) {
                continue;
            }

            const folder = zip.folder(entity.module);

            try {
                const fileData = await downloadFile(entity.s3FilePath);

                if (fileData) {
                    folder?.file(entity.deploymentObject, fileData);
                }
            } catch (error) {
                throw error;
            }
        }

        const zipBlob = await zip.generateAsync({ type: 'blob' });

        return zipBlob;
    }

    const downloadFile = async (url: string): Promise<Blob | undefined> => {
        try {
            const response = await axios.get(url, { responseType: 'blob' });

            return response.data;
        } catch {
            return undefined;
        }
    }

    const downloadBlob = (blob: Blob, filename: string) => {
        if (!blob || blob.size === 0) {
            return;
        }

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleShowModalReadyForPRD = (uatTag: TransportRequestTag) => {
        setSelectedDeploy(uatTag);
        setShowReadyForPRDModal.on();
    }

    const handleConfirmCreateTRPRD = async (tag: TransportRequestTag) => {
        try {
            screenLoader.show();
            const updateVersionResponse = await updateDllVersion(tag);
            const response = await addTransportRequestPRD(tag);

            if (response === 'success' && updateVersionResponse === 'success') {
                Swal.fire(({
                    icon: 'success',
                    title: 'success',
                    text: 'ทำการเตรียมไฟล์ขึ้น PRD เสร็จสิ้น'
                }));
            };
        }
        catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: (error as any).message
            })
        }
        finally {
            screenLoader.hide();
            fetchTransportRequest();
            setShowReadyForPRDModal.off();
        }
    }

    return {
        fappPorts,
        totalPages,
        currentItems,
        selectedDeploy,
        isOpenModalDeploy,
        refTransportRequest,
        showReadyForPRDModal,
        showAddTransportRequestModal,
        showEditransportRequestModal,

        handleDeployDll,
        handlePageChange,
        toggleModalDeploy,
        handleSearchChange,
        handleShowModalEdit,
        fetchTransportRequest,
        handleConfirmCreateTR,
        handleConfirmDeployTR,
        setShowReadyForPRDModal,
        handleConfirmCreateTRPRD,
        handleShowModalReadyForPRD,
        updateTransportRequestResult,
        handleFetchGetPathFileByUatTag,
        setShowAddTransportRequestModal,
        setShowEditTransportRequestModal,
        handleConfirmEditOrUpdateTransportRequest
    };
}

export default TransportRequestUATViewModel;
