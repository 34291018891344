import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { twMerge } from 'tailwind-merge';
import { Badge, FormControl, Select } from '@chakra-ui/react';
import { TransportRequestItemsInputFields } from './ModalCreateTransportRequest';
import { MdOutlineDelete } from "react-icons/md";

type CardTransportRequestFields = {
    file_type: string;
    file_name: string;
    core_or_noncore: string;
    add_or_edit: string;
};

export type CardTransportRequestRefHandle = {
    validateFields: () => boolean;
};

interface CardTransportRequestProps {
    fileUpload: TransportRequestItemsInputFields;
    onInputFileChange: <K extends keyof TransportRequestItemsInputFields>(
        fileUpload: TransportRequestItemsInputFields,
        key: K,
        value: TransportRequestItemsInputFields[K]
    ) => void;
    handleDeleteFile: (fileDetail: TransportRequestItemsInputFields, idex: number) => void;
    index: number
}

const CardTransportRequest = forwardRef<CardTransportRequestRefHandle, CardTransportRequestProps>(
    (props, ref) => {
        const { fileUpload, onInputFileChange, handleDeleteFile, index } = props;
        const [errorFields, setErrorFields] = useState({} as Partial<CardTransportRequestFields>);

        const cardBorderColor =
            fileUpload.module === 'FAPP'
                ? 'border-l-8 border-l-green-500'
                : fileUpload.module === 'FGUI'
                    ? 'border-l-8 border-l-teal-400'
                    : 'border-l-8 border-l-violet-600';

        const validateFields = () => {
            const requirefields: (keyof CardTransportRequestFields)[] = [
                'core_or_noncore',
                'add_or_edit'
            ];

            const nextErrorFields = requirefields.reduce((errorFields, field) => {
                if (fileUpload[field] === '') {
                    errorFields[field] = '';
                }

                return errorFields;
            }, {} as CardTransportRequestFields);

            setErrorFields(nextErrorFields);

            const isInvalid = Object.keys(nextErrorFields).some(
                field => nextErrorFields[field] === ''
            );

            if (isInvalid) {
                return false;
            }

            return true;
        };

        useImperativeHandle(ref, () => ({ validateFields }), [validateFields]);

        return (
            <div
                className={twMerge(
                    'mb-2 flex flex-col gap-2 rounded-md border-b border-r border-t border-neutral-400',
                    cardBorderColor
                )}
            >
                <div className="p-2.5">
                    <div
                        id="row-0"
                        className="flex flex-col gap-1"
                    >
                        <div className='flex flex-row justify-between'>
                            <Badge
                                variant="outline"
                                colorScheme={
                                    fileUpload.module === 'FAPP'
                                        ? 'green'
                                        : fileUpload.module === 'FGUI'
                                            ? 'teal'
                                            : 'purple'
                                }
                                className="w-fit"
                            >
                                {fileUpload.module}
                            </Badge>
                            <MdOutlineDelete className='text-red-700' onClick={() => { handleDeleteFile(fileUpload, index); }} />
                        </div>
                        <span className="mt-2 text-sm font-bold">
                            File Name : {fileUpload.file_name}
                        </span>
                    </div>
                    <div className="mt-2">
                        <div
                            id="row-1"
                            className="grid grid-cols-2 gap-2"
                        >
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col">
                                    <span className="text-sm font-medium">
                                        Is Core
                                        <span className="relative bottom-1 font-bold text-red-600">
                                            *
                                        </span>
                                    </span>
                                    <FormControl>
                                        <Select
                                            name="core_or_noncore"
                                            size="sm"
                                            className="rounded-sm border-neutral-400 text-black"
                                            placeholder="Please choose one"
                                            value={fileUpload.core_or_noncore}
                                            isInvalid={errorFields?.core_or_noncore === ''}
                                            onChange={e => {
                                                const nextErrorFields = { ...errorFields };
                                                delete nextErrorFields.core_or_noncore;
                                                setErrorFields(nextErrorFields);

                                                onInputFileChange(
                                                    fileUpload,
                                                    'core_or_noncore',
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value="CORE">Core</option>
                                            <option value="NON CORE">Non-Core</option>
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="text-sm font-medium">
                                    Add / Edit
                                    <span className="relative bottom-1 font-bold text-red-600">
                                        *
                                    </span>
                                </span>
                                <FormControl>
                                    <Select
                                        name="add_or_edit"
                                        size="sm"
                                        className="rounded-sm border-neutral-400 text-black"
                                        placeholder="Please choose one"
                                        value={fileUpload.add_or_edit}
                                        isInvalid={errorFields.add_or_edit === ''}
                                        onChange={e => {
                                            const nextErrorFields = { ...errorFields };
                                            delete nextErrorFields.add_or_edit;
                                            setErrorFields(nextErrorFields);

                                            onInputFileChange(
                                                fileUpload,
                                                'add_or_edit',
                                                e.target.value
                                            );
                                        }}
                                    >
                                        <option value="ADD">ADD</option>
                                        <option value="EDIT">EDIT</option>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

CardTransportRequest.displayName = 'CardTransportRequest';

export default CardTransportRequest;
