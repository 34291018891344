import { AxiosError } from "axios";
import { validateDataResponse } from "commons/helpers";
import {
    TransportRequestTag,
    TransportRequestEntity,
    TransportRequestPrdParams
} from "./transportRequest.schema";
import {
    callAddTransportRequestPRD,
    retrieveTransportRequestPrd
} from "./transportRequest.datasource";

export async function addTransportRequestPRD(body: TransportRequestTag) {
    try {
        const response = await callAddTransportRequestPRD(body);
        const result: string = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}

export async function getTransportRequestPrd(params: TransportRequestPrdParams) {
    try {
        const response = await retrieveTransportRequestPrd(params);
        const result: TransportRequestEntity[] = validateDataResponse(response);

        return result;
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            throw new Error(validateDataResponse(error.response).message);
        }

        throw error;
    }
}