import PortConfigStore from './PortConfig/PortConfig.store';
import TransportRequestStore from './TransportRequest/TransportRequest.store';
import AuthenticationStore from './auth/Authentication.store';
import FAPPMonitorStore from './controllers/FAPPMonitor.store';
import FTPMonitorStore from './controllers/FTPMonitor.store';
import ServerConfigStore from './controllers/ServerConfig.store';
import WebMonitorStore from './controllers/WebMonitor.store';

class RootStore {
    public serverConfigStore = new ServerConfigStore(this);
    public authentication = new AuthenticationStore(this);
    public transportRequest = new TransportRequestStore(this);
    public webMonitorStore = new WebMonitorStore(this);
    public fappMonitorStore = new FAPPMonitorStore(this);
    public ftpMonitorStore = new FTPMonitorStore(this);
    public portConfig = new PortConfigStore(this);
}

export default RootStore;
